/* This stylesheet generated by Transfonter (https://transfonter.org) on October 25, 2017 4:44 PM */

html {
  height: 100%;
}

.MuiAutocomplete-groupLabel {
  background-color: #f0f0f0 !important;
}

body {
  position: relative;
  padding: 0;
  margin: 0;
  font-size: 0.95rem;
  color: #30313c;
  min-height: 100%;
}

* {
  font-family: 'Roboto';
}

.react-swipeable-view-container {
  height: 100%;
}

@media print {
  body {
    -webkit-print-color-adjust: exact !important;                     
  }

  #feedback-menu {
    display: none !important;
  }

  .access-button {
    display: none !important;
  }
}

@media only screen and (min-width: 1000px) {
  .switch {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    margin-bottom: 4px;
  }
}

@media only screen and (max-width: 1000px) {
  .switch {
    display: none;
  }
}

.container-fluid.main-content {
  min-height: 100%;
  position: relative;
  padding-bottom: 10px;
}

.container-fluid.main-content:after {
  content: '';
  display: block;
}

.footer,
.container-fluid.main-content:after {
  height: 30px;
}

footer {
  position: absolute;
  bottom: 0;
  background: #f3f3f3;
  color: #636063;
  text-align: center;
  line-height: 54px;
  width: 100%;
  font-size: 13px;
  color: #636063;
  font-weight: 300;
}

footer img {
  transform: translateY(20%);
}

h1 {
  font-size: 24px;
  font-weight: bold;
  color: #30313c;
}

h1.page-title {
  font-size: 20px;
  font-weight: 500;
  margin: 40px auto 20px;
  padding-bottom: 8px;
  color: #30313c;
  border-bottom: 2px solid #ebebeb;
}

.page-subtitle {
  font-size: 16px;
}

h1.page-title.page-title-no-space-bottom {
  margin-bottom: 0px;
}

h2,
h3,
h4,
h5 {
  margin-bottom: 0;
  color: #30313c;
  -webkit-margin-after: 0;
}

h1.centered {
  text-align: center;
  margin-top: 3rem;
}

p {
  color: #636063;
  font-size: 13px;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
  z-index: 20;
}

.ReactModal__Content {
  border: none !important;
  border-radius: 0 !important;
  z-index: 5;
}

.topnav {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  margin: 0;
  padding: 0 20px;
}

.topnav .logo-placeholder {
  flex-basis: 10%;
}

.topnav .logo-placeholder img {
  margin: 0;
  /* margin: 12px auto 0; */
  cursor: pointer;
}

.topnav .links {
  flex-basis: 65%;
  display: flex;
  justify-content: center;
}

.topnav .user-info {
  flex-basis: 15%;
}

.topnav .user-info p {
  margin: 0;
  text-align: right;
  font-size: 13px;
  vertical-align: middle;
  color: #30313c;
  font-size: 13px !important;
  font-weight: 400;
  cursor: pointer;
}

.topnav .user-info p span {
  display: block;
  margin-top: 4px;
  color: #636063;
  text-transform: uppercase;
  font-weight: 300;
}

.topnav {
  flex-basis: 10%;
}

.user-actions {
  margin: 0 5px;
}

.topnav .user-actions .open-profile-button {
  display: flex;
  justify-content: center;
  align-items: middle;
  line-height: 30px;
}

.topnav .user-actions .open-profile-button i {
  margin-top: 10px;
  margin-right: 5px;
  color: #aaa;
}

.spaced-top {
  margin: 60px auto;
}

.little-spaced-top {
  margin: 16px auto 60px;
}

.marged-top {
  margin-top: 20px;
}

.center {
  text-align: center !important;
}

.right {
  text-align: right !important;
}

.left {
  text-align: left !important;
}
.float-left {
  float: left !important;
}
.clearboth {
  clear: both;
}

.padding-top {
  padding-top: 40px;
}

.padding-bottom {
  padding-bottom: 40px;
  height: auto;
  overflow-y: auto;
}

.navbar {
  display: flex;
  list-style: none;
  padding: 0;
}

ul.navbar {
  margin: 0;
  /* display: inline-block; */
}

ul.navbar.navbar-full {
  width: 100%;
}

ul.navbar.navbar-left {
  text-align: left;
  padding-left: 20px;
}

ul.navbar.navbar-border-bottom {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
}

ul.no-margin {
  margin-top: 0;
  margin-bottom: 0;
}

.subnavbar {
  text-align: right;
}

.navbar li {
  display: inline-block;
  padding: 10px;
  text-align: center !important;
  text-transform: uppercase;
  font-size: 13px;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  min-width: 80px;
}

.navbar li:last-child {
  margin-right: 0;
}

.navbar li.active,
.navbar li:hover {
  border-bottom: 3px solid #fbc908;
  font-weight: bold;
}

.navbar li a {
  color: inherit;
  text-decoration: none;
}
.navbar span {
  text-decoration: none;
}
.navbar li .hexagon {
  margin: 0 auto !important;
}

.navbar.navbar-equal-widths {
  margin-bottom: 0px;
}
.navbar.navbar-equal-widths li {
  width: calc(100% / 5);
  padding-right: 0;
  padding-left: 0;
  padding-top: 20px;
  margin: 0;
  text-align: center;
}
.navbar.navbar-equal-widths li:hover,
.navbar.navbar-equal-widths li.active {
  background-image: url(../images/dropdown.svg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 10%;
}
.navbar.navbar-equal-widths li:hover .hexagon,
.navbar.navbar-equal-widths li.active .hexagon {
  opacity: 1 !important;
}

/* Buttons */
.blox-button {
  display: inline-block;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 13px !important;
  text-transform: uppercase;
  font-weight: 500;
  color: #808080;
  background-color: #f3f3f3;
  height: 40px;
  width: 180px;
  border: none;
  margin-right: 18px;
  cursor: pointer;
  letter-spacing: 1px;
  border-radius: 2px;
  outline: none;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
}

.blox-button.big {
  width: auto;
  min-width: 250px;
}

.blox-button i.material-icons {
  font-size: 140%;
  transform: translateY(18%);
}

.blox-button:hover {
  background-color: #e2e2e2 !important;
}

.blox-button:last-child {
  margin-right: 0;
}

.blox-button.selected {
  color: white;
  background-color: transparent;
  border: 1px solid white;
  box-shadow: none;
  font-weight: normal;
}
.blox-button.selected:hover {
  background-color: rgba(255, 255, 255, 0.05) !important;
}

.blox-button.primary {
  color: white;
  background-color: #448aff;
}
.blox-button.primary:hover {
  background-color: #3379ee !important;
}

.blox-button.success {
  color: white;
  background-color: #66b538;
}
.blox-button.success:hover {
  background-color: #55a427 !important;
}

.blox-button.cancel {
  color: white;
  background-color: #b1b1b1;
}
.blox-button.cancel:hover {
  background-color: #a0a0a0 !important;
}
.blox-button input {
  display: none;
}
.blox-button:disabled,
.blox-button.disabled {
  opacity: 0.5;
}

.load-more-blox-button {
  background-color: #f3f3f3;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  color: #808080;
  font-family: Roboto;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  width: 304px;
  height: 40px;
}

.contract p {
  color: #636063;
  font-size: 14px;
  line-height: 22px;
}
.contract-actions {
}

.dashboard {
  margin-bottom: 100px;
}

.dashboard h1 {
  font-weight: normal;
  color: #30313c;
  font-size: 24px;
  margin-bottom: 6px;
  margin-top: 44px;
}

.dashboard h2.separator {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: #636063;
  height: 40px;
  line-height: 40px;
  padding: 0;
  background-color: #e5e5e5;
  text-transform: uppercase;
  margin: 20px auto;
}

.dashboard-card {
  padding: 25px;
  margin-top: 8px;
}

.dashboard-card p {
  margin: 0;
  color: #636063;
  font-size: 14px;
}

.dashboard-card h3 {
  font-size: 20px;
  font-weight: 500;
  color: #30313c;
  margin: 0 0 4px;
}

.chart-placeholder {
  margin-top: 30px;
}

.weekday {
  width: 20%;
  float: left;
  display: inline-block;
}

.weekday h3 {
  display: block;
  text-align: center;
  font-weight: 500;
  text-color: #30313c;
  text-transform: uppercase;
  font-size: 14px;
}

.styled-icons i {
  margin-right: 12px;
  color: inherit;
}
.styled-icons i:last-child {
  margin-right: 0;
}

.search-button span i {
  font-size: 24px;
  color: #888;
}

.blox-selection-sidebar {
  box-shadow: 2px 0px 4px -2px rgba(0, 0, 0, 0.3);
  margin-bottom: -1px;
}

.blox-selection-sidebar ul {
  list-style: none;
  padding: 0;
  margin: 40px auto;
  border-left: 2px solid #acacac;
  margin-left: 40px;
}

.blox-selection-sidebar li {
  position: relative;
  height: 29px;
  line-height: 29px;
  color: #acacac;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 22px;
  margin-left: -32px;
}

.blox-selection-non-clickable li {
  position: relative;
  cursor: default;
  height: 29px;
  line-height: 29px;
  color: #acacac;
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 22px;
  margin-left: -32px;
}

.blox-selection-non-clickable li:hover {
  color: #acacac !important;
  font-weight: normal !important;
}

.blox-selection-non-clickable li:hover i {
  background-color: #acacac !important;
}

.blox-selection-non-clickable li.active:hover {
  color: #448aff !important;
  font-weight: bold !important;
}

.blox-selection-non-clickable li.active:hover i {
  background-color: #448aff !important;
}

.blox-selection-sidebar li.active,
.blox-selection-sidebar li:hover {
  color: #448aff;
  font-weight: bold;
}

.blox-selection-sidebar li.done {
  color: #83b941;
  font-weight: bold;
}
.blox-selection-sidebar li span.label {
  margin-left: 60px;
  display: inline-flex;
  line-height: 18px;
  align-items: center;
}
.blox-selection-sidebar li:hover i.indicator {
  background-color: #448aff;
  transition: background 0.2s ease-in-out;
}

.blox-selection-sidebar li.active i.indicator {
  background-color: #448aff;
  border: 3px solid #448aff;
}

.blox-selection-sidebar li.done i.indicator {
  background-color: #83b941;
  border: 3px solid #83b941;
}

.blox-selection-sidebar li.done li.active i.indicator {
  background-color: #448aff;
  border: 3px solid #448aff;
  margin: auto 8px auto 13px;
  transition: border 0.1s linear;
}

.blox-selection-sidebar li.active i.indicator span,
.blox-selection-sidebar li.done i.indicator span {
  height: 23px;
  width: 23px;
  line-height: 23px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  border: 3px solid white;
  transition: all 0.2s ease-in-out;
}
.blox-selection-sidebar li.done i.indicator span {
  line-height: 25px;
  font-size: 20px;
}
.blox-selection-sidebar li.inactive i.indicator span {
  border: 2px solid white;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: -4px;
  height: 10px;
  width: 10px;
}

.blox-selection-sidebar li i.indicator span {
  color: white;
}

.blox-selection-sidebar li i.indicator {
  position: absolute;
  margin: auto 10px auto 16px;
  display: inline-block;
  width: 29px;
  height: 29px;
  line-height: 29px;
  background: #acacac;
  text-align: center;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  font-style: normal;
}
.blox-selection-sidebar li.inactive i.indicator {
  width: 16px;
  height: 16px;
  top: 9px;
  left: 6px;
}
.blox-selection-sidebar small {
  margin-left: 20px;
}
.blox-selection-sidebar h5 {
  margin-left: 20px;
  margin-bottom: 4px;
  margin-top: 33px;
  text-transform: uppercase;
  color: #30313c;
}

.blox-selection-placeholder {
  padding-top: 30px;
}

.blox-selection-placeholder .blox-cards {
  clear: both;
  height: auto;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-top: 20px;
}

.blox-selection-placeholder .blox-checkbox {
  position: absolute !important;
  right: 10px;
  top: 10px;
}

.blox-selection-placeholder .blox-card {
  padding: 20px 0 0 !important;
  text-align: center;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.2);
  margin-right: 20px;
  margin-bottom: 20px;
  transition: box-shadow 0.2s ease-in-out;
  position: relative;
}

.blox-selection-placeholder .blox-card .soldout,
.blox-selection-placeholder .blox-card .prerequirement_blox_done {
  background-color: #636063;
  color: white;
  font-weight: bold;
  font-size: 14px;
  line-height: 30px;
  height: 30px;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 2px;
}

.blox-selection-placeholder .blox-card .ead {
  background-color: #e1e1e1;
  color: #2b2b2b;
  font-weight: bold;
  font-size: 13px;
  line-height: 30px;
  height: 30px;
  width: 77px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 2px;
}

.blox-selection-placeholder .blox-card .required {
  background-color: #e1e1e1;
  color: #2b2b2b;
  font-weight: bold;
  font-size: 13px;
  line-height: 30px;
  height: 30px;
  width: 77px;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 2px;
}

.blox-selection-placeholder .blox-card:nth-child(3) {
  margin-right: 0 !important;
}

.blox-selection-placeholder .blox-card:hover {
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.5);
}

.blox-selection-placeholder h5 {
  text-transform: uppercase;
  margin: 0;
  font-size: 12px;
}
.blox-selection-placeholder h3 {
  color: #448aff;
  font-size: 22px;
  font-weight: bold;
  margin: 4px 0 20px;
}

.blox-selection-placeholder .blox-selection-actions {
  text-align: right;
  margin-top: 40px;
}

form span.error {
  display: block;
  color: red;
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
}

.close-button {
  border: none;
  background: none;
  padding: 10px;
  color: red !important;
  position: fixed;
  right: -5px;
  top: -5px;
  cursor: pointer;
}

.close-modal {
  font-size: 35px !important;
}

.blox-select-placeholder {
  padding-top: 30px;
  text-align: center;
}

.blox-select-placeholder button {
  width: 216px;
  height: 46px;
}

.blox-detail .header {
  color: white;
  background-color: #448aff;
  margin: -20px -20px -48px;
  padding-bottom: 50px;
}

.blox-detail .video-placeholder {
  width: 80%;
  height: 358px;
}

.blox-detail .header > div {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.blox-detail .header h1,
.blox-detail .header small {
  color: white;
}

.blox-detail .header h3 {
  color: #a0c3ff;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 0 18px;
}

.blox-detail .header h1 {
  font-size: 26px;
  font-weight: bold;
  margin: 0 0 4px;
}

.blox-detail .header small {
  font-size: 16px;
}

.blox-detail .content {
  padding: 0 10px;
}

.blox-detail .content .tabbar-content {
  padding-right: 10px;
}

.blox-detail .content h2 {
  font-size: 22px;
  font-weight: bold;
  line-height: 30px;
  margin: 10px auto;
}

.blox-detail .content p {
  color: #636063 !important;
  font-size: 16px;
  font-weight: 300;
  line-height: 23px;
  margin: 0px auto 20px;
}

.blox-detail .content .blox-placeholder {
  text-align: center;
  margin-top: -13px;
}

.blox-detail .content hr,
hr {
  border: none;
  height: 2px;
  background: #ebebeb;
  margin: 1rem auto;
}

.blox-detail .content .docent td {
  vertical-align: middle;
}

.blox-detail .content .docent .avatar img {
  border-radius: 50%;
  border: 8px solid #bcbcbc;
}

.blox-detail .content .docent .info {
  font-weight: 400;
  font-size: 16px;
  color: #636063;
  padding: 0 20px;
}
.blox-detail .content .docent .info strong {
  display: block;
  font-weight: 500;
  font-size: 18px;
  color: #30313c;
}

.blox-detail .content .docent .channels i {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #acacac;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  margin-right: 16px;
}

.blox-detail .content h4 {
  margin: 0;
  line-height: 30px;
  font-size: 14px;
}

.blox-detail .tabbar-content {
  padding: 25px 0;
}

.blox-specs {
  text-align: left;
  background: transparent;
  list-style: none;
  padding: 10px 20px 20px 24px;
  border-left: 2px solid #ebebeb;
  margin-top: -20px;
}

.blox-specs li {
  background: transparent;
  margin: 0;
  padding: 4px 0;
  line-height: 20px;
  color: #636063;
}

.blox-specs li .material-icons {
  font-size: 20px;
  margin-right: 8px;
}
.blox-specs li.divider .material-icons {
  font-size: 15px;
  color: #acacac;
  margin-left: 10px;
}

.blox-specs li.divider {
  margin-top: 20px;
  color: #30313c;
  font-weight: bold;
  background-color: transparent;
}

.profile-sidebar {
  list-style: none;
}

.profile-sidebar li {
  cursor: pointer;
  font-size: 16px;
  color: #636063;
  margin-bottom: 24px;
  height: 30px;
  line-height: 30px;
}

.profile-sidebar li.active {
  font-weight: bold;
  color: #30313c;
}

.profile-sidebar li:before {
  content: ' ';
  width: 15px;
  height: 20px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  left: -15px;
}
.profile-sidebar li.active:before {
  background: url('../images/ic_chevron_right.svg') no-repeat center 100%;
}

fieldset {
  border: none;
  width: 100%;
  display: block;
  padding: 20px 0;
  margin-bottom: 20px;
}

fieldset legend {
  font-size: 20px;
  width: 100%;
  font-weight: 500;
  padding: 16px 0 8px;
  border-bottom: 2px solid #ebebeb;
}

fieldset legend .legend-description {
  font-size: 14px;
  font-weight: 200;
}

fieldset legend span.legend-description {
  margin-left: 10px;
}

fieldset legend div.legend-description {
  margin-top: 10px;
}

fieldset h4.fieldset-subsection {
  font-size: 20px;
  font-weight: 500;
  padding: 16px 0 8px;
  width: 100%;
}

fieldset h5.fieldset-subsection {
  font-size: 18px;
  font-weight: 500;
  padding: 16px 0 8px;
  width: 100%;
  margin-bottom: 0px;
}

.hidden,
.hidden-form {
  display: none;
}

.blox-form .input-group {
  margin-top: 20px;
  color: #636063;
  font-size: 14px;
  overflow-y: hidden;
  height: auto;
  padding-bottom: 1px;
  min-height: 1px;
}

.blox-form .input-group label {
  display: block;
  margin-bottom: 8px;
}

.blox-form .input-group input[type='text'],
.blox-form .input-group input[type='number'],
.blox-form .input-group input[type='password'],
.blox-form .input-group input[type='email'],
.blox-form .input-group textarea,
input[type='search'] {
  border: 1px solid #d7d7d7;
  font-size: 14px;
  color: #636063;
  font-weight: 300;
  padding: 12px;
  border-radius: 2px;
}

input[type='search'] {
  float: none;
  height: 40px;
  -webkit-appearance: textfield;
}

.blox-form .input-group select,
.blox-select {
  -webkit-appearance: none;
  display: block;
  border: 1px solid #cfcfcf;
  padding: 12px;
  font-size: 14px;
  width: 100%;
  border-radius: 2px;
  outline: none;
  margin-right: 10px;
  padding-right: 45px;
  background-image: url('../images/dropdown.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 11px) center;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.blox-form h5 {
  margin-top: 60px;
}

.blox-form h5:first-child {
  margin-top: 0;
}

.blox-inline-select {
  display: inline-block;
  width: 200px;
  margin-right: 16px;
  margin-left: 8px;
}
.blox-inline-select:last-child {
  margin-right: 0;
}
.blox-inline-select:first-child {
  /*margin-left: 0;*/
}

textarea {
  resize: none;
}

.blox-form .input-group input[readonly] {
  background-color: #f3eded;
}

.blox-form .input-group input.small,
.blox-form .input-group textarea.small {
  width: 25%;
}
.blox-form .input-group input.medium,
.blox-form .input-group textarea.medium {
  width: 50%;
}
.blox-form .input-group input.big,
.blox-form .input-group textarea.big {
  width: 75%;
}
.blox-form .input-group input.full,
.blox-form .input-group textarea.full {
  width: 100%;
}

.smallest {
  width: auto;
  width: 24px;
  height: 24px;
  line-height: 24px;
  padding: 0;
}
.small {
  width: 25% !important;
}
.medium {
  width: 50% !important;
}
.big {
  width: 75% !important;
}
.full {
  width: calc(100% - 26px) !important;
}

.table {
  width: 100%;
  border-spacing: 0px;
  border-collapse: separate;
  margin-top: 40px;
}

.table tbody tr {
  line-height: 45px;
}

.table.centered td,
.table.centered th {
  text-align: center;
}

.table tr th {
  background-color: #ebebeb;
  color: #636063;
  padding: 11px;
  font-size: 15px;
  font-weight: 500;
}

.class-plan {
  list-style: none;
  border-left: 2px solid #d2d2d2;
  margin: 0 20px;
  padding: 0;
  position: relative;
}

.class-plan li {
  margin-left: 0;
  height: auto;
  overflow-y: auto;
  padding-bottom: 20px;
}

.class-plan li i.material-icons {
  margin-left: -20px;
  margin-right: 40px;
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  background-color: #448aff;
  text-align: center;
  font-size: 24px;
  color: white;
  position: absolute;
}

.class-plan .class-plan-content {
  margin-left: 60px;
}

.class-plan .class-plan-content h4 {
  color: #448aff;
  font-weight: normal;
  text-transform: uppercase;
  margin: 6px auto 8px;
  font-size: 13px;
}
.class-plan .class-plan-content h3 {
  font-weight: bold;
  font-size: 16px;
  margin: 0 auto 8px;
}
.class-plan .class-plan-content p {
  font-size: 14px;
  margin: 0 auto 4px;
}

.bibliography {
  font-size: 16px;
  color: #636063;
  font-weight: 300;
}

.bibliography h5 {
  text-transform: uppercase;
  background-color: #e5e5e5;
  padding: 0 50px;
  margin: 0;
  font-weight: bold;
  color: #636063;
  font-size: 14px;
  line-height: 40px;
  position: relative;
}

.bibliography h5 i {
  margin-right: 11px;
  font-size: 18px;
  display: inline;
  line-height: inherit;
  position: absolute;
  left: 14px;
}

.bibliography span {
  color: #448aff;
  margin-top: 18px;
  display: block;
}

.bibliography p a {
  font-style: normal;
  margin-top: 14px;
  text-decoration: none;
  color: inherit;
}

.bibliography.links p {
  margin: 14px auto 0;
}

.blox-cards.my-blox .padding {
  padding: 0px !important;
  width: 25% !important;
  margin-right: 20px;
}

.back-button-placeholder {
  margin-left: -40px !important;
}
.back-button-placeholder button,
button.back-button {
  font-size: 13px;
  border: none;
  background-color: transparent;
  line-height: 20px;
  height: 20px;
  text-transform: uppercase;
  color: #448aff;
  cursor: pointer;
}

.back-button {
  outline: none;
}

.back-button.back-button-dark {
  color: white !important;
}

.back-button-placeholder button i.material-icons,
button.back-button i.material-icons {
  font-size: 18px;
  float: left;
  margin-right: 10px;
}

span.ead-indicator {
  display: inline-block;
  background-color: #e1e1e1;
  color: #2b2b2b;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 30px;
  height: 30px;
  margin-left: 40px;
  width: 150px;
  text-align: center;
  border-radius: 2px;
  transform: translateY(-10%);
}

.field-group {
  margin-top: 20px;
}

.field-group * {
  color: #636063 !important;
  font-weight: 300 !important;
}

.bg-white {
  background-color: white;
}
.bg-grey {
  background-color: #fafafa;
}

.shadow-bottom {
  position: relative;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}

.filter-placeholder {
  text-align: right;
}

.filter-placeholder label {
  margin-right: 20px;
  font-weight: bold;
  font-size: 15px;
  color: #636063;
}

h2,
h4,
p {
  margin-top: 0px;
  margin-bottom: 8px;
}

svg {
  transition: all 0.3 ease;
}

h4 {
  text-transform: uppercase;
  font-size: 13px;
}
h5 {
  font-size: 18px;
}
h2 {
  font-size: 22px;
}
p {
  color: #636063;
}

a.progress-link {
  color: #448aff;
  text-decoration: underline;
  cursor: pointer;
  display: block;
  text-align: right;
}

a.progress-link:hover {
  text-decoration: none;
}

.header.student-header {
  margin-bottom: 0;
  padding-bottom: 0;
}

.header.student-header p {
  color: white !important;
  font-size: 16px;
  margin: 0;
  line-height: 20px;
}

.header.student-header .close-button {
  position: absolute;
  top: -20px;
  right: -20px;
}

.header.student-header .student-info {
  height: 55px;
  display: table-cell;
  vertical-align: middle;
}

iframe {
  border: none;
}

.badged {
  position: relative;
}

.badged span.badge {
  display: block;
  font-weight: bold;
  height: 20px;
  width: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  color: #30313c;
  background-color: #fbc908;
  border-radius: 50%;
  position: absolute;
  right: -20px;
  top: -20px;
}

.badged.badge-right span.badge {
  top: 50%;
  transform: translateY(-50%);
  /*right: auto;*/
}

.blox-list h4 {
  margin-bottom: 20px;
}

.my-blox-sidebar {
  padding-left: 14px;
  margin-bottom: 60px;
  width: 180px;
}

.my-blox-sidebar li {
  font-size: 15px;
  margin-bottom: 10px;
  width: 130px;
  white-space: nowrap;
}

.search-field {
  padding-left: 40px !important;
  background-image: url('../images/search.svg');
  background-repeat: no-repeat;
  background-position: 10px center;
  margin-right: 12px;
}

.blox-card .hexagon {
  margin-right: 0 !important;
}

.no-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0;
  margin-bottom: 0;
}

.no-padding-top {
  padding-top: 0 !important;
}

.professor-cards-selection {
  padding-top: 0;
}

.call-tooltip {
  color: #b1b1b1;
  transform: translateY(20%);
  cursor: help;
}

label i.call-tooltip {
  font-size: 130%;
  margin-left: 10px;
}

.type-dark * {
  color: white !important;
}

.tooltip-extra-class {
  max-width: 350px;
}

.tooltip-extra-class--left span {
  text-align: left !important;
}

.history-tooltip {
  font-size: 18px !important;
  padding-left: 3px;
}

.slick-next:before,
.slick-prev:before {
  color: black;
  content: unset;
}

.slick-arrow .material-icons {
  color: #30313c !important;
  font-size: 40px;
}

.slick-arrow {
  height: 40px;
}

.slick-prev {
  left: -50px;
}
.slick-next {
  right: -30px;
}

.slick-arrow.slick-disabled .material-icons {
  opacity: 0.1;
}

.blox-col .hexagon {
  margin-right: 0px;
}

.recharts-responsive-container {
  margin: auto !important;
}

a.blox {
  color: #448aff;
  display: inline-block;
  margin-top: 10px;
}

a.clear {
  text-decoration: none;
}

a.horizontal-spaced {
  margin-left: 20px;
}
a.remove {
  color: #c21b1c;
}

.large {
  height: 50px;
  line-height: 50px;
  width: 216px;
}

.no-space {
  margin: 0 !important;
}

.blox-selection-sidebar.navigation-purpose {
  box-shadow: none;
}

.blox-selection-sidebar.navigation-purpose ul {
  margin-top: 20px;
  margin-left: 20px !important;
  box-shadow: none !important;
}

.bibliograpy-placeholder {
  margin-bottom: 60px;
}

.breadcrumb {
  font-size: 14px;
}

.history-container {
  padding: 0 70px;
}

.history-container .expander-container {
  padding: 15px 0;
  font-size: 14px;
}

.history-container .expander-container .section-data.blox-name {
  flex-basis: 40%;
}

.history-container .expander-container .section-data.blox-course-name {
  flex-basis: 20%;
}

.history-container .expander-container .section-data.blox-enrollment-period {
  flex-basis: 10%;
}

.history-container .expander-container .section-data span {
  display: block;
}
.history-container .expander-container .section-data .section-title {
  margin-bottom: 15px;
  font-weight: 700;
}

#menu-icon {
  display: none;
}

#menu-icon .material-icons {
  color: #636063 !important;
}

@media only screen and (min-width: 767px) {
  .blox-col {
    float: left;
    margin-right: 10px;
  }
  .blox-col.blox-col-20 {
    width: calc(20% - 10px) !important;
  }
  .blox-selection-placeholder .blox-card {
    width: 30.5% !important;
  }

  .logout {
    display: none !important;
  }
}

@media only screen and (max-width: 700px) {
  #menu-icon {
    display: inline-block;
  }

  .topnav {
    justify-content: space-between;
  }

  .topnav .links {
    justify-content: flex-end;
  }

  nav ul {
    display: none;
    position: absolute;
    right: 20px;
    top: 60px;
    width: 50%;
    border-radius: 4px 0 4px 4px;
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.15);
    background: #fff;
    z-index: 100;
  }

  nav li {
    text-align: center;
    width: 100%;
    margin: 0;
    padding: 10px 0 10px 0 !important;
    margin-right: 0 !important;
  }

  nav ul.responsive {
    display: block;
  }

  .confirmation-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative !important;
    margin-top: 40px;
  }

  .confirmation-back-button {
    position: relative !important;
    font-size: 10px !important;
  }

  .confirmation-confirm-button {
    position: relative !important;
  }

  .confirmation-confirm-button .blox-button {
    font-size: 10px !important;
  }

  .user-info,
  .user-actions {
    display: none;
  }

  .confirm-button {
    width: 35% !important;
  }

  .filter-blox {
    margin: 0px 0px 15px auto !important;
    width: 100% !important;
  }

  .search-blox {
    width: 85% !important;
  }
}

.load-blox-btn {
  text-align: center;
  color: #448aff;
  border-style: solid;
  border-width: 1px;
  border-color: #acacac;
  cursor: pointer;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  height: 18px;
}

.lds-facebook div {
  display: inline-block;
  width: 13px;
  background: #448aff;
  margin-bottom: 0;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
  animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
  margin: 0 4px;
  animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
  animation-delay: 0;
}

.editor-padding div [class*='DraftStyleDefault'] {
  margin: 0;
}

@keyframes lds-facebook {
  0% {
    height: 15px;
  }
  50%,
  100% {
    height: 8px;
  }
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Medium'), local('Roboto-Medium'), url('../fonts/Roboto-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold'), local('Roboto-Bold'), url('../fonts/Roboto-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url('../fonts/Roboto-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), local('Roboto-Regular'), url('../fonts/Roboto-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Italic'), local('Roboto-Italic'), url('../fonts/Roboto-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'), url('../fonts/Roboto-ThinItalic.woff2') format('woff2');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url('../fonts/Roboto-BoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url('../fonts/Roboto-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Light'), local('Roboto-Light'), url('../fonts/Roboto-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Thin'), local('Roboto-Thin'), url('../fonts/Roboto-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local(Material Icons), url(../fonts/Material-Icons.woff2) format('woff2'),
    url(../fonts/Material-Icons.woff) format('woff'), url(../fonts/Material-Icons.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.SlideIn-appear {
  transform: translateX(30px);
  opacity: 0;
}
.SlideIn-appear.SlideIn-appear-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.6s linear;
}
.SlideIn-enter {
  opacity: 0;
  transform: translateX(30px);
}
.SlideIn-enter.SlideIn-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.2s linear 0.4s;
}
.SlideIn-leave {
  opacity: 1;
  transform: translateX(0);
}
.SlideIn-leave.SlideIn-leave-active {
  opacity: 0;
  position: absolute;
  width: 100%;
  transform: translateX(-30px);
  transition: all 0.2s linear;
}

.offering-general-parameters-field {
  margin: 0;
  width: 98%;
}


